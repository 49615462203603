html.lenis {
  height: auto;
}
.lenis.lenis-smooth {
  scroll-behavior: auto;
}
.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}
.lenis.lenis-stopped {
  overflow: hidden;
}
.lenis.lenis-scrolling iframe {
  pointer-events: none;
}
#sun,
#star,
#matter {
  transform: translateZ(0);
}
#bathing {
  background: url(/img/nathan-anderson-woman-sunbathing.png) no-repeat center bottom;
  background-size: contain;
  position: fixed;
  z-index: 18;
  height: 100vh;
  width: 100%;
  bottom: -100vh;
  transition: all 3000ms cubic-bezier(0.075, 0.785, 0.315, 0.96);
}
body {
  background: linear-gradient(to right, #A60000, #DB0F0F);
  background-attachment: fixed ;
  font-family: 'Sora', sans-serif;
  color: #fff;
  transition: background 4300ms ease-in-out;
}
body:not(.s_0) #s_1 .description {
  opacity: 1 !important;
}
body.s_4 {
  background: linear-gradient(to right, #00074D, #000A6C);
}
body.s_4 #sun {
  bottom: 38vw;
  transform: scale(48%);
  box-shadow: 0 0 8vw 2vw #FFFFFFD9 0 0 8vw 12vw #D79A9A, 5vw 0 90vw 18vw #FFD2B14A, 0 0 90vw 34vw #FFB18B78, 10vw 0 100vw 44vw #FFB6806B, 0vw 10vw 100vw 74vw #FFB5B526, 0vw 10vw 60vw 85vw #FFD2BC26, inset 0 0 7vw 5vw #EED4D4;
}
body.s_4 #bathing {
  bottom: -12vh;
}
body.s_4 #s_5 {
  padding-bottom: 24vh;
}
body.dev * {
  box-shadow: inset 0 0 2px 2px black;
}
a {
  color: #fff;
  text-decoration: none;
}
h1 {
  font-weight: 300;
  font-size: clamp(22px, 4.5vw, 87px);
  text-align: left;
  padding-left: 33vw;
  padding-right: 10vw;
  position: sticky;
  top: 9.1vh;
  margin: 0;
  line-height: 1.1em;
}
h1.fixed {
  position: fixed;
}
h1 span {
  display: block;
}
h1 span:last-child {
  text-align: right;
}
.logo {
  position: fixed;
  top: 9.1vh;
  z-index: 2046;
  line-height: 1.4rem;
  left: calc(33vw - 3em);
  height: clamp(22px, 3.4vw, 87px);
  margin-top: 0.7vw;
}
#sun {
  transition: all 3000ms cubic-bezier(0.075, 0.785, 0.315, 0.96);
  position: fixed;
  z-index: 3;
  width: 32vw;
  height: 32vw;
  border-radius: 100%;
  background: #FFE9D9;
  box-shadow: 0 0 8vw 2vw #FFE9D9, 0 0 8vw 12vw #FDE7D8, 5vw 0 90vw 18vw #FFD2B1AD, 0 0 90vw 34vw #FFB18B78, 10vw 0 100vw 44vw #FFB6806B, 0vw 10vw 100vw 74vw #FFB5B526, 0vw 10vw 60vw 85vw #FFD2BC26;
  bottom: -18vh;
  left: -2vw;
  animation: breathing 3s alternate ease-in infinite;
}
#sun:before,
#sun:after {
  transition: all 3000ms cubic-bezier(0.075, 0.785, 0.315, 0.96);
  position: absolute;
  display: block;
  content: "";
}
#sun:before {
  background: #3a4037;
  background: linear-gradient(174deg, #3a4037 5%, #000000 100%);
  width: 80%;
  height: 60%;
  transform: translate(4vw, 1vw);
  z-index: 2;
}
#sun:after {
  background: #ecf1e9;
  background: linear-gradient(174deg, #ecf1e9 5%, #d0e0d8 100%);
  width: 70%;
  height: 12%;
  transform: translate(10vw, -12vw) rotate(-7deg);
  z-index: 3;
  box-shadow: inset 0 -0.1vh 1vw 1vw #88888891, 0 -0.1vh 1vw 1vw #fff4, 0 -0.1vh 1vw 2vw #76956145;
  animation: noise 1.5s alternate ease-in infinite;
  border-radius: 7%;
}
#sun img {
  transition: all 3000ms cubic-bezier(0.075, 0.785, 0.315, 0.96);
  position: relative;
  z-index: 4;
  max-width: 100%;
}
#sun .number {
  transition: all 3000ms cubic-bezier(0.075, 0.785, 0.315, 0.96);
  font-size: clamp(8px, 0.9vw, 20px);
  position: absolute;
  z-index: 23;
  color: #9ace9a;
  transform: translate(9vw, -19vw) rotate(-3deg);
}
#sun .number i {
  animation: blinking 0.9s alternate ease-in infinite;
}
#star {
  will-change: transform;
  position: fixed;
  bottom: -6vw;
  left: 30vw;
  z-index: 27;
  width: 13vw;
  height: 13vw;
  transform-origin: -200% 300%;
  background: linear-gradient(to right, white, white);
  filter: contrast(170%) brightness(1000%);
  border-radius: 100%;
  box-shadow: 0 0 3vw 0 #fff;
  transition: all 900ms cubic-bezier(0.075, 0.785, 0.315, 0.96);
  transform: rotate(20deg);
  mix-blend-mode: plus-lighter;
}
#matter {
  will-change: all;
  position: fixed;
  bottom: -6vw;
  right: 30vw;
  z-index: 8;
  width: 27vw;
  height: 27vw;
  transform-origin: 100vw -105vh;
  background: #910000;
  background: linear-gradient(156deg, #910000 5%, #5b0000 100%);
  border-radius: 100%;
  box-shadow: inset 1vw 0 3vw 3vw #ffffff2e, 1vw 0 1vw 1vw #ffffff0a;
  transition: all 1900ms cubic-bezier(0.075, 0.785, 0.315, 0.96);
  transform: rotate(-50deg);
}
#matter img {
  max-width: 150%;
  transition: all 1900ms cubic-bezier(0.075, 0.785, 0.315, 0.96);
}
.clients {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-auto-rows: 1fr;
  margin-left: 15vw;
  width: 48vw;
}
.clients .item {
  border-bottom: 1px solid #fff6;
  border-right: 1px solid #fff6;
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;
}
.clients .item:nth-child(5n+5) {
  border-right: 1px solid transparent;
}
.clients .item:nth-child(11),
.clients .item:nth-child(12),
.clients .item:nth-child(13),
.clients .item:nth-child(14),
.clients .item:nth-child(15) {
  border-bottom: 1px solid transparent;
}
.clients .item img {
  display: inline-block;
  width: 70%;
  margin: 1rem 0;
}
.clients .item img[alt="meetfactory"] {
  width: 60%;
}
.clients .item img[alt="zachran-jidlo"] {
  width: 80%;
}
.clients .item img[alt="auto-mat"] {
  padding-bottom: 0.8rem;
}
.clients .item img[alt="amu"] {
  width: 45%;
}
.clients .item img[alt="hnp"] {
  width: 50%;
}
.s_1 .clients {
  display: none;
}
.screen {
  min-height: 100svh;
  min-height: 100vh;
  padding-bottom: 30vh;
  width: 100%;
  position: relative;
  z-index: 20;
}
.note {
  padding-left: 60vw;
  padding-top: 113vh;
  font-size: clamp(18px, 2.3vw, 46px);
  color: #000;
  font-weight: 500;
}
.note .cont {
  transform: rotate(-7deg);
  transform-origin: 0 100%;
}
.note a {
  color: #000;
}
.description {
  position: fixed;
  top: 40vh;
  padding-left: 25vw;
  padding-right: 6vw;
  font-size: clamp(18px, 2.3vw, 56px);
  opacity: 0;
  transition: opacity 400ms ease-in-out;
}
.description.points {
  font-size: clamp(14px, 2.3vw, 56px);
}
.points {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 3rem;
  grid-auto-rows: 1fr;
  margin: 0 15vw;
  width: 80vw;
  padding: 0;
}
.points h2 {
  font-size: 1.4rem;
}
.points p {
  font-size: clamp(1rem, 1vw, 1.2rem);
}
.exposed .description,
.exposed .note {
  opacity: 1;
}
@keyframes noise {
  0% {
    transform: translate(6vw, -8.6vw) rotate(-7deg);
    opacity: 0.7;
  }
  17% {
    transform: translate(6vw, -8vw) rotate(-7deg);
    opacity: 0.6;
  }
  57% {
    transform: translate(6vw, -9vw) rotate(-7deg);
    opacity: 0.6;
  }
  100% {
    transform: translate(6vw, -9.5vw) rotate(-7deg);
    opacity: 0.7;
  }
}
@keyframes breathing {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.05);
  }
}
@keyframes blinking {
  0% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}
@media screen and (max-width: 578px) {
  .logo {
    top: 11.4vh;
    left: calc(20vw - 3em);
    height: clamp(32px, 3.4vw, 87px);
  }
  #sun {
    bottom: -4vh;
  }
  #star {
    bottom: 6vh;
    transform-origin: -600% 700%;
  }
  #matter {
    bottom: 11vh;
    scale: 1.1;
  }
  h1 {
    top: 13.1vh;
    font-size: 1.7rem;
    padding-left: 21vw;
  }
  .clients {
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 1fr;
    margin-left: 5vw;
    width: 90vw;
    padding-left: 0;
    padding-right: 0;
  }
  .clients .dummy {
    display: none;
  }
  .clients .item:nth-child(4) {
    border-right: 1px solid transparent;
  }
  .clients .item:nth-child(8) {
    border-right: 1px solid transparent;
  }
  .clients .item:nth-child(10) {
    border-right: 1px solid #fff6;
  }
  .clients .item:nth-child(12) {
    border-right: 1px solid transparent;
    border-bottom: 1px solid #fff6;
  }
  .description {
    padding-left: 22vw;
  }
  #s_1 .description {
    padding-left: 0;
  }
  .points {
    grid-template-columns: 1fr;
    padding-top: 70vh;
    padding-bottom: 50vh;
    padding-left: 21vw;
    margin: 0;
    width: 70vw;
    height: 100vh;
  }
  .points .item {
    width: 65vw;
    white-space: break-space;
  }
  body #sun {
    opacity: 0;
  }
  body.s_1 #sun {
    opacity: 1;
  }
  body.s_3 .description,
  body.s_4 .description {
    opacity: 1 !important;
    position: static;
  }
  body.s_4 #sun {
    bottom: 86vw;
  }
  body.s_4 #bathing {
    bottom: 0;
  }
  .note {
    padding-left: 43vw;
    padding-top: 70vh;
  }
  .note .cont {
    transform: rotate(-8.5deg);
  }
}
